import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f2075cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "software" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "deviece" }
const _hoisted_4 = { class: "image" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "text" }
const _hoisted_7 = { class: "title-zh" }
const _hoisted_8 = { class: "title-en" }
const _hoisted_9 = { class: "content" }
const _hoisted_10 = {
  key: 0,
  class: "property"
}
const _hoisted_11 = { class: "property-list" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "value" }
const _hoisted_14 = {
  key: 1,
  class: "intro"
}
const _hoisted_15 = { class: "label" }
const _hoisted_16 = { class: "content" }
const _hoisted_17 = {
  key: 2,
  class: "params"
}
const _hoisted_18 = { class: "params-list" }
const _hoisted_19 = { class: "title" }
const _hoisted_20 = { class: "table-box" }
const _hoisted_21 = {
  key: 0,
  class: "value"
}
const _hoisted_22 = {
  key: 1,
  class: "value"
}
const _hoisted_23 = {
  key: 3,
  class: "intro"
}
const _hoisted_24 = { class: "desc-list" }
const _hoisted_25 = { class: "label" }
const _hoisted_26 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyHeader = _resolveComponent("MyHeader")!
  const _component_MyBreadCrumb = _resolveComponent("MyBreadCrumb")!
  const _component_CommonFooter = _resolveComponent("CommonFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MyHeader, { currentMenuIndex: 3 }),
    _createVNode(_component_MyBreadCrumb, {
      listName: "软件产品",
      detailName: _ctx.devieceData.zhName,
      onReturnList: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('return-list')))
    }, null, 8, ["detailName"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.devieceData.img,
            alt: ""
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.devieceData.zhName), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.devieceData.enName), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.devieceData.content), 1)
        ])
      ]),
      (_ctx.propertyData.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propertyData, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "property-item",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(item.label), 1),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(item.value), 1)
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (JSON.stringify(_ctx.introData) !== '{}')
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.introData.label), 1),
            _createElementVNode("div", _hoisted_16, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.introData.textObj, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "text",
                  key: index
                }, _toDisplayString(item), 1))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.paramsData.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paramsData, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "params-item",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_19, _toDisplayString(item.title), 1),
                  _createElementVNode("div", _hoisted_20, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.childrenList, (item2, index2) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(Math.floor(index2 / 4) % 2 === 0 ?  'sub-item' : 'sub-item bg-gray'),
                        key: index2
                      }, [
                        _createElementVNode("div", {
                          class: "label",
                          style: _normalizeStyle(`font-size: ${item2.label.length > 11 ? '11px' : _ctx.auto}`)
                        }, _toDisplayString(item2.label), 5),
                        _createElementVNode("div", {
                          class: "value-list",
                          style: _normalizeStyle(`font-size:${item2.value3 ? '11px' : item2.value2 ? '13px' : '15px'}`)
                        }, [
                          _createElementVNode("div", {
                            class: "value",
                            style: _normalizeStyle(`font-size: ${item2.value.length > 20 ? '13px' : _ctx.auto}`)
                          }, _toDisplayString(item2.value), 5),
                          (item2.value2)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(item2.value2), 1))
                            : _createCommentVNode("", true),
                          (item2.value3)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(item2.value3), 1))
                            : _createCommentVNode("", true)
                        ], 4)
                      ], 2))
                    }), 128))
                  ])
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (JSON.stringify(_ctx.otherData) !== '{}')
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherData.descList, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "desc-item",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_25, _toDisplayString(item.label), 1),
                  _createElementVNode("div", _hoisted_26, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.textObj, (item2, index2) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "text",
                        key: index2
                      }, _toDisplayString(item2), 1))
                    }), 128))
                  ])
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_CommonFooter)
  ]))
}