
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CommonFooter',
  props: {
    listName: {
      type: String,
      default: ''
    },
    detailName: {
      type: String,
      default: ''
    }
  },
  emits: ['return-list']
})
